<template>
    <main>
        <HeaderTab :title="$t('mouvement.mouvements')" return_path="/horse" />
        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <CustomTable
								ref="mouv_table"
                                :id_table="id_table"
                                primaryKey="mouvement_id"
                                selectMode="single"
								:transformer="['MouvementTransformer', 'withHorseAndType']"
								:date-filter="dateFilter"
								:base-filters="filters"
                                :busy.sync="table_busy"
                                :hrefsRoutes="config_table_hrefs"
								:persistDateFilter="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>


<script type="text/javascript">
    import Navigation from "@/mixins/Navigation.js";

	export default {
		name: "mouvementListe",
		mixins: [Navigation],
		data () {
			return {
				id_table: "mouvements",
                config_table_hrefs: {
                    'horse.horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    }
                },

				filters: {
					exclude_initial_prov: {
						column: 'mouvement_date',
						operator: 'isNotEqualTo',
						value: "0000-00-00"
					},
					horse_not_inactive: {
						column: 'horse.horse_inactive',
						operator: 'isEqualTo',
						value: 0
					}
				},

                /* Configuration du tableau : */
				table_busy: true,
				dateFilter: null,
    
                events_tab: {
                    'TableAction::DeleteMouvements': this.handleDeleteMouvements,
                }
			}
		},
		mounted() {
            this.init_component()
		},
		methods: {
            async init_component() {
				this.setDateFilter()
			},
			
			setDateFilter() {
				let start = null
				let end = null
				
				if(this.getConfig('startDate' + this.id_table)) {
					start = new Date(this.getConfig('startDate' + this.id_table))
				}
				else {
					start = new Date(new Date().setMonth(new Date().getMonth() - 4))
				}

				if(this.getConfig('endDate' + this.id_table)) {
					end = new Date(this.getConfig('endDate' + this.id_table))
				}

				this.dateFilter = {
					column: 'mouvement_date',
					start,
					end
				}
			},

			reloadMouvementsListe() {
				this.$refs.mouv_table.refreshTable()
			},

			async handleDeleteMouvements(mouvements) {
				await this.applyDeleteMouvements(mouvements)
				this.reloadMouvementsListe()
			}
		},
		components: {
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
